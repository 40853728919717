import { Box, Typography, useMediaQuery } from '@mui/material'
import { scaleValue } from '../../../lib/utils'
import { Box1, ArrowButton, JornalModal } from 'architecture-front-end'
import React from 'react'
import { JornalDTO } from '../../../dto'

type RightContainerProps = {
  boxStyle?: React.CSSProperties
  scale?: number
  onClickArrow?: () => void
  jornais: JornalDTO[]
}

const BuildJornal = ({ jornal }: { jornal: JornalDTO }) => {
  const [isJornalOpen, setIsJornalOpen] = React.useState(false)

  return (
    <>
      <Box1
        display='flex'
        flexDirection='column'
        onClick={() => setIsJornalOpen(true)}
        sx={{ height: 'auto', width: 'auto', padding: '4px', alignItems: 'center', cursor: 'zoom-in' }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: 'black',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          {jornal.materia_titulo}
        </Typography>
        <Typography
          sx={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '12px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            letterSpacing: 0.15,
            wordWrap: 'break-word',
          }}
        >
          {jornal.materia_texto.substring(0, 160).replaceAll(RegExp(/<p>|<\/p>/g), '')}
        </Typography>
      </Box1>
      <JornalModal
        title={jornal.materia_titulo}
        content={jornal.materia_texto.replaceAll(RegExp(/<p>|<\/p>/g), '')}
        modalIsOpen={isJornalOpen}
        onClose={() => setIsJornalOpen(false)}
      />
    </>
  )
}

function RightContainer({ jornais, boxStyle, scale, onClickArrow }: RightContainerProps): JSX.Element {
  scale = scale ?? 1
  const minDesktopSize = useMediaQuery('(min-width: 1020px)')
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)')
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)')

  let padding = '38px 24px'

  if (minDesktopSize) {
    padding = '2px 1px'
    scale = 0.8
  }
  if (mediumDesktopSize) {
    padding = '16px 12px'
    scale = 1
  }
  if (largeDesktopSize) {
    padding = '38px 24px'
    scale = 1
  }

  const scaledBoxStyle: React.CSSProperties = {
    width: scaleValue(scale, 302 - 24 * 2),
    height: scaleValue(scale, 885 - 38 * 2),
    background: 'white',
    border: '1px #E0E0E0 solid',
    padding: padding,
    alignItems: 'center',
    gap: '12px',
    position: 'relative',
    overflowY: 'scroll',
  }

  return (
    <>
      <Box display='flex' flexDirection='column' sx={{ ...scaledBoxStyle, ...boxStyle }}>
        <Typography sx={{color: 'black', fontSize: '24px', fontFamily: 'Poppins', fontWeight: '700', letterSpacing: 0.15, wordWrap: 'break-word'}}>Jornal</Typography>
        {jornais.map((jornal, index) => (
          <BuildJornal key={index} jornal={jornal} />
        ))}
      </Box>
      <ArrowButton
        direction='right'
        buttonStyle={{ position: 'absolute', left: '-9%', top: '45%' }}
        onClick={onClickArrow}
      />
    </>
  )
}

export default React.memo(RightContainer)