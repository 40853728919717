export const login = async (email: string, senha: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, senha }),
  })

  if (!response.ok) {
    throw new Error('Login failed')
  }

  const data = await response.json()

  // Salvando token e usuário no localStorage
  localStorage.setItem('token', data.token)
  localStorage.setItem('user', JSON.stringify(data.user))

  return data
}

export const loginWithToken = async (token: string) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login/me`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw new Error('Login failed')
  }

  const data = await response.json()

  // Salvando token e usuário no localStorage
  localStorage.setItem('token', token)
  localStorage.setItem('user', JSON.stringify(data))

  return data
}
