export const replaceObjWithChanges = (objOriginal, changes) => {
  const newObject = { ...objOriginal } // Cria um clone superficial do objeto original

  Object.keys(changes).forEach((key) => {
    // Verifica se o valor em changes é um objeto e não um array
    if (typeof changes[key] === 'object' && !Array.isArray(changes[key]) && changes[key] !== null) {
      // Se o original for também um objeto, faz a substituição recursiva
      if (typeof newObject[key] === 'object' && newObject[key] !== null) {
        newObject[key] = replaceObjWithChanges(newObject[key], changes[key])
      } else {
        // Se o original não for um objeto, simplesmente substitui
        newObject[key] = { ...changes[key] }
      }
    } else {
      // Se não for objeto, substitui diretamente
      newObject[key] = changes[key]
    }
  })

  return newObject
}
