import { Box } from '@mui/material'
import { IconCardList, InvoiceContainer, ProductContainer, TabTable } from 'architecture-front-end'
import React from 'react'
import ProductStockContainer from '../../Organismos/ProductStockContainer'
import RhContainer from '../../Organismos/RhContainer'
import FinanceContainer from '../../Organismos/FinanceContainer'
import { FolhaDecisaoDTO } from '../../../dto'
import { getDataFromLocalStorageOrFallback, transformStringsToNumbers } from '../../../lib/utils'
import CompanyReport from '../CompanyReport'
import MarketReport from '../MarketReport'
import { EtapaDTO } from '../../../dto/etapaDTO'
import { Formarters } from '../../../lib/formaters'

const mappedSubProductNameByKey: Record<string, string> = {
  A: '🍉🍆 FRUTAS & LEGUMES',
  B: '🧴🧹 HIGIENE & LIMPEZA',
  C: '📱💻 ELETRÔNICOS',
  D: '🧸🎲 BRINQUEDOS',
  E: '🛳️🌍 IMPORTADOS',
}

function MeuEstoqueContent({ scale = 1, folhaDecisaoData }: { scale?: number; folhaDecisaoData: FolhaDecisaoDTO }) {
  function createProductStockContainers(folhaDecisaoData: FolhaDecisaoDTO, scale?: number) {
    return Object.keys(folhaDecisaoData.stock_values).map((key) => (
      <ProductStockContainer
        key={key}
        scale={scale}
        productName={`Produto ${key}`}
        subProductName={mappedSubProductNameByKey[key]}
        rowOne={{
          valueInputOne: folhaDecisaoData.stock_values[key].estoque_inicial,
          valueInputTwo: folhaDecisaoData.stock_values[key].estoque_entradas,
          valueInputThree: folhaDecisaoData.stock_values[key].estoque_saidas,
        }}
        rowTwo={{
          valueInputOne: folhaDecisaoData.stock_values[key].estoque_perdas,
          valueInputTwo: folhaDecisaoData.stock_values[key].estoque_final,
          valueInputThree: folhaDecisaoData.stock_values[key].estoque_em_transito,
        }}
        rowThree={{
          valueInputOne: folhaDecisaoData.stock_values[key].custo_medio,
          valueInputTwo: folhaDecisaoData.stock_values[key].saldo_final,
          valueInputThree: folhaDecisaoData.stock_values[key].qtd_nao_atendida,
        }}
        rowFour={{
          valueInputOne: folhaDecisaoData.stock_values[key].multa_nao_atendimento,
        }}
        stockQuantity={folhaDecisaoData.stock_values[key].estoque_final}
        onChange={function (data: any): void {
          throw new Error('Function not implemented.')
        }}
      />
    ))
  }

  return (
    <Box display='flex' flexDirection='column' gap='32px'>
      {createProductStockContainers(folhaDecisaoData, scale)}
    </Box>
  )
}
const MemoMeuEstoqueContent = React.memo(MeuEstoqueContent)

function ProdutosContainer({
  scale = 1,
  onChange,
  folhaDecisaoData,
  dataEtapa,
  midContainerData,
}: {
  scale?: number
  onChange: (data: any) => void
  folhaDecisaoData: FolhaDecisaoDTO
  dataEtapa: EtapaDTO
  midContainerData: any
}) {
  const [cardSelected, setCardSelected] = React.useState<string>('Sign')
  const midContainerDataFromLocalStorage = JSON.parse(sessionStorage.getItem('midContainerData') ?? '{}')

  const { compras } = getDataFromLocalStorageOrFallback<any>(midContainerDataFromLocalStorage, folhaDecisaoData, [
    'compras.A.prazo_pagamento',
    'compras.A.preco_custo',
    'compras.A.qtde_compra',
    'compras.B.prazo_pagamento',
    'compras.B.preco_custo',
    'compras.B.qtde_compra',
    'compras.C.prazo_pagamento',
    'compras.C.preco_custo',
    'compras.C.qtde_compra',
    'compras.D.prazo_pagamento',
    'compras.D.preco_custo',
    'compras.D.qtde_compra',
    'compras.E.prazo_pagamento',
    'compras.E.preco_custo',
    'compras.E.qtde_compra',
  ])

  const { vendas } = getDataFromLocalStorageOrFallback<any>(midContainerDataFromLocalStorage, folhaDecisaoData, [
    'vendas.A.prazo_pagamento',
    'vendas.A.preco_venda',
    'vendas.A.tx_financiamento_vendedor_porc',
    'vendas.A.com_entrada',
    'vendas.B.prazo_pagamento',
    'vendas.B.preco_venda',
    'vendas.B.tx_financiamento_vendedor_porc',
    'vendas.B.com_entrada',
    'vendas.C.prazo_pagamento',
    'vendas.C.preco_venda',
    'vendas.C.tx_financiamento_vendedor_porc',
    'vendas.C.com_entrada',
    'vendas.D.prazo_pagamento',
    'vendas.D.preco_venda',
    'vendas.D.tx_financiamento_vendedor_porc',
    'vendas.D.com_entrada',
    'vendas.E.prazo_pagamento',
    'vendas.E.preco_venda',
    'vendas.E.tx_financiamento_vendedor_porc',
    'vendas.E.com_entrada',
  ])

  const { financas } = getDataFromLocalStorageOrFallback<any>(midContainerDataFromLocalStorage, folhaDecisaoData, [
    'financas.aplicacao_1.deposito',
    'financas.aplicacao_1.saque',
    'financas.aplicacao_2.deposito',
    'financas.aplicacao_2.prazo',
    'financas.emprestimo_normal.deposito',
    'financas.emprestimo_normal.prazo',
  ])

  const { contas_pagar } = getDataFromLocalStorageOrFallback<any>(midContainerDataFromLocalStorage, folhaDecisaoData, [
    'contas_pagar.desconto_duplicatas',
    'contas_pagar.antecipacao_pgtos',
    'contas_pagar.ampliacao_m2',
    'contas_pagar.diversos',
  ])

  const { rh } = getDataFromLocalStorageOrFallback<any>(midContainerDataFromLocalStorage, folhaDecisaoData, [
    'rh.operacionais.admitidos',
    'rh.vendedores.admitidos',
    'rh.operacionais.demitidos',
    'rh.vendedores.demitidos',
    'rh.operacionais.salarios',
    'rh.vendedores.salarios',
    'rh.vendedores.comissao',
    'rh.vendedores.hora_extra',
    'rh.operacionais.hora_extra',
    'rh.vendedores.treinamento',
    'rh.operacionais.treinamento',
    'rh.operacionais.participacao',
  ])

  const { marketing } = getDataFromLocalStorageOrFallback<any>(midContainerDataFromLocalStorage, folhaDecisaoData, [
    'marketing.A.tv',
    'marketing.A.midias_sociais',
    'marketing.A.radio',
    'marketing.A.impressos',
    'marketing.B.tv',
    'marketing.B.midias_sociais',
    'marketing.B.radio',
    'marketing.B.impressos',
    'marketing.C.tv',
    'marketing.C.midias_sociais',
    'marketing.C.radio',
    'marketing.C.impressos',
    'marketing.D.tv',
    'marketing.D.midias_sociais',
    'marketing.D.radio',
    'marketing.D.impressos',
    'marketing.E.tv',
    'marketing.E.midias_sociais',
    'marketing.E.radio',
    'marketing.E.impressos',
  ])

  const validadores = folhaDecisaoData.validadores

  function createProductContainer(folhaDecisaoData: FolhaDecisaoDTO, scale?: number) {
    const searchCustPriceByPaymentTerm = (key: string) => {
      if (compras[key]?.prazo_pagamento === 1 || compras[key]?.prazo_pagamento === 0) {
        return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa
      } else if (compras[key]?.prazo_pagamento === 2) {
        return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas
      } else if (compras[key]?.prazo_pagamento === 3) {
        return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas
      } else if (compras[key]?.prazo_pagamento === 20) {
        return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas_sem_entrada
      } else if (compras[key]?.prazo_pagamento === 30) {
        return (dataEtapa.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas_sem_entrada
      } else {
        return 0
      }
    }

    return Object.keys(folhaDecisaoData.stock_values).map((key) => (
      <ProductContainer
        key={key}
        scale={scale}
        productName={`Produto ${key}`}
        subProductName={mappedSubProductNameByKey[key]}
        checkboxStatus={vendas[key]?.com_entrada}
        rowOne={{
          valueInputOne: [
            {
              label: 'Não comprar',
              value: '0',
              key: `compras.${key}.prazo_pagamento`,
              defaultValue: {
                label: 'not in use',
                value: compras[key]?.prazo_pagamento,
              },
            },
            {
              label: 'Comprar à vista',
              value: '1',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '1+1',
              value: '2',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '1+2',
              value: '3',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '0+2',
              value: '20',
              key: `compras.${key}.prazo_pagamento`,
            },
            {
              label: '0+3',
              value: '30',
              key: `compras.${key}.prazo_pagamento`,
            },
          ],
          valueInputTwo: {
            key: `compras.${key}.preco_custo`,
            disabled: true,
            value: searchCustPriceByPaymentTerm(key).toFixed(2).toString().replace('.', ','),
          },
          valueInputThree: {
            key: `compras.${key}.qtde_compra`,
            value: compras[key]?.qtde_compra,
            helperText: `Max: ${validadores.produtos[key]?.qtd_compra_maximo}`,
            validator: (value) => {
              if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.produtos[key]?.qtd_compra_maximo))
                return 'Quantidade de compra maior que o permitido'
              return null
            },
          },
        }}
        rowTwo={{
          valueInputOne: [
            {
              label: 'Não vender',
              value: '0',
              key: `vendas.${key}.prazo_pagamento`,
              defaultValue: {
                label: 'not in use',
                value: vendas[key]?.prazo_pagamento,
              },
            },
            {
              label: 'À vista',
              value: '1',
              key: `vendas.${key}.prazo_pagamento`,
            },
            {
              label: '2 parcelas',
              value: '2',
              key: `vendas.${key}.prazo_pagamento`,
            },
            {
              label: '3 parcelas',
              value: '3',
              key: `vendas.${key}.prazo_pagamento`,
            },
          ],
          valueInputTwo: {
            key: `vendas.${key}.preco_venda`,
            value: vendas[key]?.preco_venda.toFixed(2).toString().replace('.', ','),
            helperText: `Min: $${validadores.produtos[key]?.preco_venda_minimo} / Max: $${validadores.produtos[key]?.preco_venda_maximo}`,
            validator: (value) => {
              if (
                Formarters.parseNumberWithComma(value) < Formarters.parseNumberWithComma(validadores.produtos[key]?.preco_venda_minimo) ||
                Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.produtos[key]?.preco_venda_maximo)
              )
                return 'Preço de venda fora do intervalo permitido'
              return null
            },
          },
          valueInputThree: {
            key: `vendas.${key}.tx_financiamento_vendedor_porc`,
            value: vendas[key]?.tx_financiamento_vendedor_porc.toFixed(2).toString().replace('.', ','),
            helperText: `Max: 25%`,
            validator: (value) => {
              if (
                Formarters.parseNumberWithComma(value) > 25
              )
                return 'Taxa de financiamento maior que o permitido'
              return null
            },
          },
        }}
        rowThree={{
          valueInputOne: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.impressos`,
              defaultValue: { label: 'not in use', value: marketing[key]?.impressos },
            },
            { label: '1', value: '1', key: `marketing.${key}.impressos` },
            { label: '2', value: '2', key: `marketing.${key}.impressos` },
            { label: '3', value: '3', key: `marketing.${key}.impressos` },
            { label: '4', value: '4', key: `marketing.${key}.impressos` },
            { label: '5', value: '5', key: `marketing.${key}.impressos` },
            { label: '6', value: '6', key: `marketing.${key}.impressos` },
            { label: '7', value: '7', key: `marketing.${key}.impressos` },
            { label: '8', value: '8', key: `marketing.${key}.impressos` },
            { label: '9', value: '9', key: `marketing.${key}.impressos` },
          ],
          valueInputTwo: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.midias_sociais`,
              defaultValue: { label: 'not in use', value: marketing[key]?.midias_sociais },
            },
            {
              label: '1',
              value: '1',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '2',
              value: '2',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '3',
              value: '3',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '4',
              value: '4',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '5',
              value: '5',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '6',
              value: '6',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '7',
              value: '7',
              key: `marketing.${key}.midias_sociais`,
            },
            {
              label: '8',
              value: '8',
              key: `marketing.${key}.midias_sociais`,
            },
            { label: '9', value: '9', key: `marketing.${key}.midias_sociais` },
          ],
          valueInputThree: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.radio`,
              defaultValue: { label: 'not in use', value: marketing[key]?.radio },
            },
            { label: '1', value: '1', key: `marketing.${key}.radio` },
            { label: '2', value: '2', key: `marketing.${key}.radio` },
            { label: '3', value: '3', key: `marketing.${key}.radio` },
            { label: '4', value: '4', key: `marketing.${key}.radio` },
            { label: '5', value: '5', key: `marketing.${key}.radio` },
            { label: '6', value: '6', key: `marketing.${key}.radio` },
            { label: '7', value: '7', key: `marketing.${key}.radio` },
            { label: '8', value: '8', key: `marketing.${key}.radio` },
            { label: '9', value: '9', key: `marketing.${key}.radio` },
          ],
          valueInputFour: [
            {
              label: '0',
              value: '0',
              key: `marketing.${key}.tv`,
              defaultValue: { label: 'not in use', value: marketing[key]?.tv },
            },
            { label: '1', value: '1', key: `marketing.${key}.tv` },
            { label: '2', value: '2', key: `marketing.${key}.tv` },
            { label: '3', value: '3', key: `marketing.${key}.tv` },
            { label: '4', value: '4', key: `marketing.${key}.tv` },
            { label: '5', value: '5', key: `marketing.${key}.tv` },
            { label: '6', value: '6', key: `marketing.${key}.tv` },
            { label: '7', value: '7', key: `marketing.${key}.tv` },
            { label: '8', value: '8', key: `marketing.${key}.tv` },
            { label: '9', value: '9', key: `marketing.${key}.tv` },
          ],
        }}
        onChange={function (data: any, error: string | null): void {
          if(error) return
          if(data[`compras.${key}.prazo_pagamento`] === "0") {
            data[`compras.${key}.preco_custo`] = 0
            data[`compras.${key}.qtde_compra`] = 0
          }
          if(data[`vendas.${key}.prazo_pagamento`] === "0") {
            data[`vendas.${key}.preco_venda`] = 0
            data[`vendas.${key}.tx_financiamento_vendedor_porc`] = 0
          }
          if(Formarters.parseNumberWithComma(data[`vendas.${key}.preco_venda`]) < Formarters.parseNumberWithComma(validadores.produtos[key]?.preco_venda_minimo) ||
              Formarters.parseNumberWithComma(data[`vendas.${key}.preco_venda`]) > Formarters.parseNumberWithComma(validadores.produtos[key]?.preco_venda_maximo)) {
                data[`vendas.${key}.preco_venda`] = '0'
            }
          if(Formarters.parseNumberWithComma(data[`vendas.${key}.tx_financiamento_vendedor_porc`]) > 25) {
            data[`vendas.${key}.tx_financiamento_vendedor_porc`] = '0'
          }
          if(Formarters.parseNumberWithComma(data[`compras.${key}.qtde_compra`]) > Formarters.parseNumberWithComma(validadores.produtos[key]?.qtd_compra_maximo)) {
            data[`compras.${key}.qtde_compra`] = '0'
          }
          onChange(transformStringsToNumbers(data))
        }}
      />
    ))
  }

  return (
    <Box>
      <div style={{ marginBottom: '20px' }}>
        <IconCardList
          onChange={(icon) => setCardSelected(icon.name)}
          initialSelected='Sign'
          icons={[
            { name: 'Sign', label: 'Compra/Venda Marketing' },
            { name: 'InvoiceTip', label: 'Finanças' },
            { name: 'StackedBarUp', label: 'Contas' },
            { name: 'RhIcon', label: 'RH' },
          ]}
        />
      </div>
      {cardSelected === 'Sign' && (
        <Box display='flex' flexDirection='column' gap='32px'>
          {createProductContainer(folhaDecisaoData, scale)}
        </Box>
      )}
      {cardSelected === 'InvoiceTip' && (
        <FinanceContainer
          scale={scale}
          rowOne={{
            valueInputOne: {
              key: 'financas.aplicacao_1.deposito',
              value: financas.aplicacao_1.deposito.toFixed(2).toString().replace('.', ','),
              helperText: `Max: $${validadores.aplicacao_1.deposito_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.aplicacao_1.deposito_maximo))
                  return 'Valor de depósito maior que o permitido'
                return null
              },
            },
            valueInputTwo: {
              key: 'financas.aplicacao_1.saque',
              value: financas.aplicacao_1.saque.toFixed(2).toString().replace('.', ','),
              helperText: `Saque Disponível: $${validadores.saque_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.saque_maximo)) return 'Valor de saque maior que o permitido'
                return null
              },
            },
            valueInputThree: {
              key: '',
              value: '',
              disabled: true,
            },
          }}
          rowTwo={{
            valueInputOne: {
              key: 'financas.aplicacao_2.deposito',
              value: financas.aplicacao_2.deposito.toFixed(2).toString().replace('.', ','),
              helperText: `Max: $${validadores.aplicacao_2.deposito_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.aplicacao_2.deposito_maximo))
                  return 'Valor de depósito maior que o permitido'
                return null
              },
            },
            valueInputTwo: [
              {
                label: '0',
                value: '0',
                key: 'financas.aplicacao_2.prazo',
                defaultValue: '0',
              },
              {
                label: '2',
                value: '2',
                key: 'financas.aplicacao_2.prazo',
                defaultValue: financas.aplicacao_2.prazo,
              },
              {
                label: '3',
                value: '3',
                key: 'financas.aplicacao_2.prazo',
                defaultValue: financas.aplicacao_2.prazo,
              },
            ],
          }}
          rowThree={{
            valueInputOne: {
              key: 'financas.emprestimo_normal.deposito',
              value: financas.emprestimo_normal.deposito.toFixed(2).toString().replace('.', ','),
            },
            valueInputTwo: [
              {
                label: '0',
                value: '0',
                key: 'financas.emprestimo_normal.prazo',
                defaultValue: '0',
              },
              {
                label: '2',
                value: '2',
                key: 'financas.emprestimo_normal.prazo',
                defaultValue: financas.emprestimo_normal.prazo,
              },
              {
                label: '3',
                value: '3',
                key: 'financas.emprestimo_normal.prazo',
                defaultValue: financas.emprestimo_normal.prazo,
              },
            ],
          }}
          onChange={function (data: any, error: string | null): void {
            if(error) return
            const newData = transformStringsToNumbers(data)
            if (newData['financas.aplicacao_2.prazo'] === 0 && newData['financas.aplicacao_2.deposito'] > 0) {
              newData['financas.aplicacao_2.prazo'] = 2
            }
            if (newData['financas.emprestimo_normal.prazo'] === 0 && newData['financas.emprestimo_normal.deposito'] > 0) {
              newData['financas.emprestimo_normal.prazo'] = 2
            }
            onChange(newData)
          }}
        />
      )}
      {cardSelected === 'StackedBarUp' && (
        <InvoiceContainer
          scale={scale}
          rowOne={{
            valueInputOne: {
              key: 'contas_pagar.desconto_duplicatas',
              value: contas_pagar.desconto_duplicatas.toFixed(2).toString().replace('.', ','),
              helperText: `Max: $${validadores.desconto_duplicatas_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.desconto_duplicatas_maximo))
                  return 'Valor de desconto maior que o permitido'
                return null
              },
            },
          }}
          rowTwo={{
            valueInputOne: {
              key: 'contas_pagar.antecipacao_pgtos',
              value: contas_pagar.antecipacao_pgtos.toFixed(2).toString().replace('.', ','),
              helperText: `Max: $${validadores.antecipacao_pagamentos_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.antecipacao_pagamentos_maximo))
                  return 'Valor de antecipação maior que o permitido'
                return null
              },
            },
          }}
          rowThree={{
            valueInputOne: {
              key: 'contas_pagar.ampliacao_m2',
              value: contas_pagar.ampliacao_m2.toFixed(2).toString().replace('.', ','),
            },
          }}
          rowFour={{
            valueInputOne: {
              key: 'contas_pagar.diversos',
              value: contas_pagar.diversos.toFixed(2).toString().replace('.', ','),
              disabled: true,
            },
          }}
          onChange={function (data: any, error: string | null): void {
            if(error) return
                        
            if (Formarters.parseNumberWithComma(data['contas_pagar.desconto_duplicatas']) > Formarters.parseNumberWithComma(validadores.desconto_duplicatas_maximo))
              data['contas_pagar.desconto_duplicatas'] = '0'
            if (Formarters.parseNumberWithComma(data['contas_pagar.antecipacao_pgtos']) > Formarters.parseNumberWithComma(validadores.antecipacao_pagamentos_maximo))
              data['contas_pagar.antecipacao_pgtos'] = '0'

            onChange(transformStringsToNumbers(data))
          }}
        />
      )}
      {cardSelected === 'RhIcon' && (
        <RhContainer
          scale={scale}
          rowOne={{
            valueInputOne: {
              key: 'rh.operacionais.admitidos',
              value: rh.operacionais.admitidos,
            },
            valueInputTwo: {
              key: 'rh.vendedores.admitidos',
              value: rh.vendedores.admitidos,
            },
          }}
          rowTwo={{
            valueInputOne: {
              key: 'rh.operacionais.demitidos',
              value: rh.operacionais.demitidos,
              helperText: `Max: ${validadores.demitidos_operacionais_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.demitidos_operacionais_maximo))
                  return 'Demissões maior que o permitido'
                return null
              },
            },
            valueInputTwo: {
              key: 'rh.vendedores.demitidos',
              value: rh.vendedores.demitidos,
              helperText: `Max: ${validadores.demitidos_vendedores_maximo}`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > Formarters.parseNumberWithComma(validadores.demitidos_vendedores_maximo))
                  return 'Demissões maior que o permitido'
                return null
              },
            },
          }}
          rowThree={{
            valueInputOne: {
              key: 'rh.operacionais.salarios',
              value: rh.operacionais.salarios.toFixed(2).toString().replace('.', ','),
              helperText: `Min: $${validadores.salario_operacionais_maximo}`, // salario_operacionais_maximo == minimo (erro label api) 
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) < Formarters.parseNumberWithComma(validadores.salario_operacionais_maximo))
                  return 'Salário menor que o permitido'
                return null
              },
            },
            valueInputTwo: {
              key: 'rh.vendedores.salarios',
              value: rh.vendedores.salarios.toFixed(2).toString().replace('.', ','),
              helperText: `Min: $${validadores.salario_vendedores_maximo}`, // salario_operacionais_maximo == minimo (erro label api)
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) < Formarters.parseNumberWithComma(validadores.salario_vendedores_maximo))
                  return 'Salário menor que o permitido'
                return null
              },
            },
          }}
          rowFour={{
            valueInputTwo: [
              {
                label: '0',
                value: '0',
                key: 'rh.vendedores.comissao',
                defaultValue: { label: rh.vendedores.comissao, value: rh.vendedores.comissao },
              },
              { label: '1', value: '1', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
              { label: '2', value: '2', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
              { label: '3', value: '3', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
              { label: '4', value: '4', key: 'rh.vendedores.comissao', defaultValue: { label: '0', value: '0' } },
            ],
          }}
          rowFive={{
            valueInputOne: {
              key: 'rh.operacionais.hora_extra',
              value: rh.operacionais.hora_extra,
              helperText: `Max: 25h`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > 25)
                  return 'Horas extras maior que o permitido'
                return null
              },
            },
            valueInputTwo: {
              key: 'rh.vendedores.hora_extra',
              value: rh.vendedores.hora_extra,
              helperText: `Max: 25h`,
              validator: (value) => {
                if (Formarters.parseNumberWithComma(value) > 25)
                  return 'Horas extras maior que o permitido'
                return null
              },
            },
          }}
          rowSix={{
            valueInputOne: [
              {
                label: '0',
                value: '0',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '1',
                value: '1',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '2',
                value: '2',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '3',
                value: '3',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '4',
                value: '4',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '5',
                value: '5',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '6',
                value: '6',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '7',
                value: '7',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '8',
                value: '8',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
              {
                label: '9',
                value: '9',
                key: 'rh.operacionais.treinamento',
                defaultValue: { label: rh.operacionais.treinamento, value: rh.operacionais.treinamento },
              },
            ],
            valueInputTwo: [
              {
                label: '0',
                value: '0',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '1',
                value: '1',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '2',
                value: '2',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '3',
                value: '3',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '4',
                value: '4',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '5',
                value: '5',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '6',
                value: '6',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '7',
                value: '7',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '8',
                value: '8',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
              {
                label: '9',
                value: '9',
                key: 'rh.vendedores.treinamento',
                defaultValue: { label: rh.vendedores.treinamento, value: rh.vendedores.treinamento },
              },
            ],
          }}
          rowSeven={{
            valueInputOne: [
              {
                label: '0',
                value: '0',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '1',
                value: '1',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '2',
                value: '2',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '3',
                value: '3',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '4',
                value: '4',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '5',
                value: '5',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '6',
                value: '6',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '7',
                value: '7',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '8',
                value: '8',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
              {
                label: '9',
                value: '9',
                key: 'rh.operacionais.participacao',
                defaultValue: { label: rh.operacionais.participacao, value: rh.operacionais.participacao },
              },
            ],
          }}
          onChange={function (data: any, error: string | null): void {
            if(error) return
            if(Formarters.parseNumberWithComma(data['rh.operacionais.salarios']) < Formarters.parseNumberWithComma(validadores.salario_operacionais_maximo)) {
              data['rh.operacionais.salarios'] = '0'
            }
            if(Formarters.parseNumberWithComma(data['rh.vendedores.salarios']) < Formarters.parseNumberWithComma(validadores.salario_vendedores_maximo)) {
              data['rh.vendedores.salarios'] = '0'
            }
            if(Formarters.parseNumberWithComma(data['rh.operacionais.hora_extra']) > 25) {
              data['rh.operacionais.hora_extra'] = '0'
            }
            if(Formarters.parseNumberWithComma(data['rh.vendedores.hora_extra']) > 25) {
              data['rh.vendedores.hora_extra'] = '0'
            }
            if(Formarters.parseNumberWithComma(data['rh.operacionais.demitidos']) > Formarters.parseNumberWithComma(validadores.demitidos_operacionais_maximo)) {
              data['rh.operacionais.demitidos'] = '0'
            }
            if(Formarters.parseNumberWithComma(data['rh.vendedores.demitidos']) > Formarters.parseNumberWithComma(validadores.demitidos_vendedores_maximo)) {
              data['rh.vendedores.demitidos'] = '0'
            }

            onChange(transformStringsToNumbers(data))
          }}
        />
      )}
    </Box>
  )
}

function MidContainer({
  folhaDecisaoData,
  midContainerData,
  dataEtapa,
  scale = 1,
  boxStyle,
  onChange,
}: {
  folhaDecisaoData: FolhaDecisaoDTO
  dataEtapa: EtapaDTO
  midContainerData: any
  scale?: number
  boxStyle?: React.CSSProperties
  onChange: (data: any) => void
}) {
  return (
    <Box sx={{ height: '80%', alignContent: 'center', ...boxStyle }}>
      <TabTable
        tabBoxStyle={{ position: 'relative', height: '100%' }}
        tabs={[
          {
            tabTitle: 'Produtos',
            tabChild: (
              <ProdutosContainer
                scale={scale}
                onChange={onChange}
                folhaDecisaoData={folhaDecisaoData}
                dataEtapa={dataEtapa}
                midContainerData={midContainerData}
              />
            ),
          },
          {
            tabTitle: 'Meu Estoque',
            tabChild: <MemoMeuEstoqueContent scale={scale} folhaDecisaoData={folhaDecisaoData} />,
          },
          { tabTitle: 'Relatório de Empresa', tabChild: <CompanyReport /> },
          { tabTitle: 'Relatório do Mercado', tabChild: <MarketReport /> },
        ]}
      />
    </Box>
  )
}

const isPrazoPagamentoDiff = (prevProps: any, nextProps: any) => {
  if (nextProps === null) {
    return false
  }

  const keys = ['A', 'B', 'C', 'D', 'E']
  let isDiff = false
  for (let key of keys) {
    if (prevProps !== null && prevProps.compras !== '0') {
      if (prevProps.compras[key]?.prazo_pagamento !== nextProps.compras[key]?.prazo_pagamento) {
        isDiff = true
      }
    } else {
      if (nextProps.compras !== '0' || nextProps.compras !== null) {
        (nextProps.compras[key]?.prazo_pagamento)
      }
      if ((nextProps.compras !== '0' || nextProps.compras !== null) && nextProps.compras[key]?.prazo_pagamento) {
        isDiff = true
      }
    }
  }
  return isDiff
}

export default MidContainer
// export default React.memo(MidContainer, (prevProps, nextProps) => {
//   // console.log(prevProps.midContainerData !== null)
//   // console.log(prevProps.midContainerData !== nextProps.midContainerData)
//   // if(prevProps.midContainerData !== null && (prevProps.midContainerData !== nextProps.midContainerData)) {
//   //   console.log('oi')
//   //   return false
//   // }
//   // console.log(prevProps.midContainerData)
//   // console.log(nextProps.midContainerData)
//   // if(nextProps.midContainerData !== null && nextProps.midContainerData.prazo_pagamento) {
//   //   console.log('xau')
//   //   return false
//   // }
//   if(isPrazoPagamentoDiff(prevProps.midContainerData, nextProps.midContainerData)) {
//     return false
//   }
//   if(prevProps.folhaDecisaoData !== nextProps.folhaDecisaoData) {
//     return false
//   }
//   if(prevProps.dataEtapa !== nextProps.dataEtapa) {
//     return false
//   }
//   if(prevProps.scale !== nextProps.scale) {
//     return false
//   }

//   return true
// })