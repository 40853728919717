import { Box, Typography } from '@mui/material'
import { Button1, RankingBox, NavigationButtons, UserProfileBox } from 'architecture-front-end'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { scaleValue } from '../../../lib/utils'
import React, { useEffect } from 'react'
import { Ranking, UserDTO } from '../../../dto'
import { GameDetailsDTO } from '../../../dto/gameDetailsDTO'

type LeftContainerProps = {
  boxStyle?: React.CSSProperties
  ranking: Ranking[]
  gameDetails: GameDetailsDTO
}

const scale = 1

const scaledBoxStyle: React.CSSProperties = {
  width: scaleValue(scale, 302 - 24 * 2),
  height: scaleValue(scale, 1080 - 38 * 2),
  background: 'white',
  border: '1px #E0E0E0 solid',
  padding: `${scaleValue(scale, 38)}px ${scaleValue(scale, 24)}px`,
}

function LeftContainer({ gameDetails, boxStyle, ranking }: LeftContainerProps): JSX.Element {
  const [userData, setUserData] = React.useState<UserDTO>()

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (!!user) setUserData(JSON.parse(user))
  }, [])

  function getNamesFromRanking(ranking: Ranking[]): string[] {
    return ranking.map((u) => u.name)
  }

  function handleNavigationChange(value: string) {
    // possiveis valores serão: MyCompanies | Tutorial | Support
    if(value.toLowerCase() === 'tutorial') window.open(process.env.REACT_APP_TUTORIAL_URL)
  }

  return (
    <Box display='flex' flexDirection='column' sx={{ ...scaledBoxStyle, ...boxStyle }}>
      <UserProfileBox
        name={userData?.nome || ''}
        company={gameDetails.empresa.empresa}
        scenario={gameDetails.nome_cenario}
        scenarioCircleColor='green'
        boxStyle={{ marginBottom: scaleValue(scale, '56px'), width: 'auto' }}
      />
      <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
        <Box>
          <Typography
            sx={{
              color: 'black',
              fontSize: 16,
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Navegue
          </Typography>
          <NavigationButtons onChange={handleNavigationChange} boxStyle={{ marginBottom: scaleValue(scale, '24px') }} />
          <RankingBox subtitle='Ranking de usuários' names={getNamesFromRanking(ranking)} />
        </Box>
        <Button1
          text='Sair da Simulação'
          icon={<LogoutOutlinedIcon sx={{ fontSize: scaleValue(scale, 20) }} />}
          isActive={true}
          onClick={() => {
            sessionStorage.removeItem('midContainerData')
            localStorage.removeItem('id_etapa')
            localStorage.removeItem('jogo_id')
            localStorage.removeItem('id_empresa')
            localStorage.removeItem('id_empresas')
            localStorage.removeItem('numero_etapa')

            if(process.env.REACT_APP_VUE_WEBSITE_URL)
              window.location.href = process.env.REACT_APP_VUE_WEBSITE_URL
            else
              history.back()
          }}
        />
      </Box>
    </Box>
  )
}

export default React.memo(LeftContainer)