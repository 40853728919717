export class Formarters {
  static formatMoney(value: number | string): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Formarters.parseNumberWithComma(value))
  }

  static parseNumberWithComma(n: any) {
    if (typeof n === 'string') {
      const parsed = n.replace(',', '.')
      return Number(parsed)
    }
    return Number(n)
  }
}
