import { Box, Grid, Typography } from '@mui/material'
import { Box1, Input1, HelpTooltip, DropdownSelect } from 'architecture-front-end'
import React, { act, useEffect, useState } from 'react'
import { scaleValue } from '../../../lib/utils'
import { Formarters } from '../../../lib/formaters'

export type DropdownSelectOption = {
  label: string
  value: string
  key?: string
  defaultValue?: DropdownSelectOption
}

export type RowInputProp = {
  key: string
  value: string
  helperText?: string
  validator?: (value: string) => string | null
}

export type Row = {
  valueInputOne?: RowInputProp | DropdownSelectOption[]
  valueInputTwo?: RowInputProp | DropdownSelectOption[]
}

export type RhContainerProps = {
  rowOne: Row
  rowTwo: Row
  rowThree: Row
  rowFour: Row
  rowFive: Row
  rowSix: Row
  rowSeven: Row
  onChange: (data: any, error: string | null) => void
  scale?: number // Adicionando a prop scale
}

const operatorHelpText: Record<string, string> = {
  'admitidos': 'Operacionais: aumento do número de operacionais. Auxilia no MRH-CO.',
  'demitidos': 'Operacionais: redução da força de trabalho dos operacionais.',
  'salario': 'Operacionais: define-se o salário dos operacionais.',
  'horaExtra': 'Operacionais: define-se o valor entre 0 e 25%.',
  'treinamento': 'Operacionais: define-se o valor entre 0 e 9. Cada investimento em treinamento, por exemplo, 3, será aplicado um percentual de 30% sobre os salários dos respectivos colaboradores.',
  'participacao': 'Operacionais: participação nos lucros após os impostos, de 0 a 9.',
}

const salerHelpText: Record<string, string> = {
  'admitidos': 'Vendedores: aumento do número de vendedores. Auxilia no MRH-CV.',
  'demitidos': 'Vendedores: redução da força de trabalho dos vendedores.',
  'salario': 'Vendedores: define-se o salário dos vendedores.',
  'horaExtra': 'Vendedores: define-se o valor entre 0 e 25%.',
  'treinamento': 'Vendedores: define-se o valor entre 0 e 9. Cada investimento em treinamento, por exemplo, 3, será aplicado um percentual de 30% sobre os salários dos respectivos colaboradores.',
  'comissao': 'Vendedores: indica o percentual (%) sobre as vendas brutas (receita bruta), pago aos vendedores.',
}

const textStyle: any = (scale: number) => ({
  color: 'black',
  fontSize: scaleValue(scale, 22),
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: 0.15,
  wordWrap: 'break-word',
})

function RowContainer({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
}: {
  title: string
  hasOperators?: boolean
  hasSales?: boolean
  rowValue: Row
  onChangeInputOne: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void
  onChangeInputTwo: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void
  scale?: number
  tooltipHelpTextKey: string
  validator?: (value: string) => string | null // Função de validação como prop
}) {
  const [errorOne, setErrorOne] = useState<string | null>(null) // Estado para o erro do primeiro input
  const [errorTwo, setErrorTwo] = useState<string | null>(null) // Estado para o erro do segundo input
  const [actualValueOne, setActualValueOne] = useState<string | undefined>(undefined); // Estado para o valor atual
  const [actualValueTwo, setActualValueTwo] = useState<string | undefined>(undefined); // Estado para o valor atual

  // Função para validar e atualizar o estado de erro para o primeiro input
  const handleInputOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.' , ',')
    let isError = false;
    let validationError : string | null = null;

    if ((rowValue.valueInputOne as RowInputProp).validator) {
      validationError = (rowValue.valueInputOne as RowInputProp).validator!(value)
      isError = !!validationError;
      setErrorOne(validationError) // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!Formarters.parseNumberWithComma(value)) {
        isError = true
      }
      if (!isError) {
        value = parseInt(value).toString()
      }
    }

    if ((rowValue.valueInputOne as RowInputProp)?.key.includes('salarios')) {
      isError = false
    }

    if (Formarters.parseNumberWithComma(value) >= 0 || value === '') {
      if (!isError) {
        setActualValueOne(value);
        onChangeInputOne(event, validationError) // Chama a função de onChange
      }
    }
  }

  // Função para validar e atualizar o estado de erro para o segundo input
  const handleInputTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace('.' , ',')
    let isError = false;
    let validationError : string | null = null;

    if ((rowValue.valueInputTwo as RowInputProp).validator) {
      validationError = (rowValue.valueInputTwo as RowInputProp).validator!(value)
      isError = !!validationError;
      setErrorTwo(validationError) // Atualiza o erro se houver
    }

    if (value !== '') {
      if (!Formarters.parseNumberWithComma(value)) {
        isError = true
      }
      if (!isError) {
        value = parseInt(value).toString()
      }
    }


    if ((rowValue.valueInputTwo as RowInputProp)?.key.includes('salarios')) {
      isError = false
    }

    if (Formarters.parseNumberWithComma(value) >= 0 || value === '') {
      if (!isError) {
        setActualValueTwo(value);
        onChangeInputTwo(event, validationError) // Chama a função de onChange
      }
    }
  }

  function notHasEmployees() {
    return (
      <Typography
        variant='h6'
        align='right'
        sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}
      >
        Não Possui
      </Typography>
    )
  }

  function buildTooltipText() {
    let text = ''
    if (hasOperators && !hasSales && operatorHelpText.hasOwnProperty(tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey]
    }
    if (hasSales && !hasOperators && salerHelpText.hasOwnProperty(tooltipHelpTextKey)) {
      text += salerHelpText[tooltipHelpTextKey]
    }
    if (hasSales && hasOperators && operatorHelpText.hasOwnProperty(tooltipHelpTextKey) && salerHelpText.hasOwnProperty(tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey] + '\n\n' + salerHelpText[tooltipHelpTextKey]
    }
    return text
  }

  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={buildTooltipText()} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <Input1
            defaultValue={(rowValue.valueInputOne as RowInputProp).value}
            value={actualValueOne}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={handleInputOneChange}
            error={!!errorOne} // Define o estado de erro
            helperText={errorOne || (rowValue.valueInputOne as RowInputProp).helperText} // Mostra o erro ou o helperText
            isControlledComponent
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <Input1
            defaultValue={(rowValue.valueInputTwo as RowInputProp).value}
            value={actualValueTwo}
            sx={{ marginTop: scaleValue(scale, '4px'), width: '100%' }}
            onChange={handleInputTwoChange}
            error={!!errorTwo} // Define o estado de erro
            helperText={errorTwo || (rowValue.valueInputTwo as RowInputProp).helperText} // Mostra o erro ou o helperText
            isControlledComponent
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
    </>
  )
}


function DropdownSelectBoxState({ scale, options, dropdownValueParam, onSelect }: { scale: number, options: DropdownSelectOption[], dropdownValueParam?: DropdownSelectOption, onSelect: (option: DropdownSelectOption) => void }) {
  const [dropdownValue, setDropdownValue] = useState<DropdownSelectOption | undefined>(dropdownValueParam)

  const handleChangeSelect = (option: DropdownSelectOption) => {
    setDropdownValue(option)
    onSelect(option)
  }

  return (
    <DropdownSelect
      boxStyle={{
        marginTop: scaleValue(scale, '4px'),
        marginLeft: 'auto',
        width: '100%',
        boxSizing: 'border-box',
      }}
      label=''
      options={options}
      dropdownValue={dropdownValue}
      onSelect={handleChangeSelect}
    />
  )
}


function RowContainerSelect({
  title,
  hasOperators = true,
  hasSales = true,
  rowValue,
  onChangeInputOne,
  onChangeInputTwo,
  tooltipHelpTextKey = '',
  scale = 1,
}: {
  title: string
  hasOperators?: boolean
  hasSales?: boolean
  rowValue: Row
  onChangeInputOne: (option: DropdownSelectOption) => void
  onChangeInputTwo: (option: DropdownSelectOption) => void
  tooltipHelpTextKey: string
  scale?: number
}) {
  function notHasEmployees() {
    return (
      <Typography
        variant='h6'
        align='right'
        sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}
      >
        Não Possui
      </Typography>
    )
  }

  function buildTooltipText() {
    let text = ''
    if (hasOperators && !hasSales && operatorHelpText.hasOwnProperty(tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey]
    }
    if (hasSales && !hasOperators && salerHelpText.hasOwnProperty(tooltipHelpTextKey)) {
      text += salerHelpText[tooltipHelpTextKey]
    }
    if (hasSales && hasOperators && operatorHelpText.hasOwnProperty(tooltipHelpTextKey) && salerHelpText.hasOwnProperty(tooltipHelpTextKey)) {
      text += operatorHelpText[tooltipHelpTextKey] + '\n\n' + salerHelpText[tooltipHelpTextKey]
    }
    return text
  }

  return (
    <>
      <Grid item xs={12} sm={4} sx={{ placeSelf: 'center' }}>
        <Box display='flex' gap={scaleValue(scale, '4px')} alignItems='center' justifyContent='start'>
          <Typography sx={{ ...textStyle(scale) }}>{title}</Typography>
          <HelpTooltip tooltipText={buildTooltipText()} size={scaleValue(scale, 18) as number} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasOperators ? (
          <DropdownSelectBoxState
            scale={scale}
            options={rowValue.valueInputOne as DropdownSelectOption[]}
            dropdownValueParam={(rowValue.valueInputOne as DropdownSelectOption[])[0].defaultValue}
            onSelect={onChangeInputOne}
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
      <Grid item xs={12} sm={4} textAlign='end'>
        {hasSales ? (
          <DropdownSelectBoxState
            scale={scale}
            options={rowValue.valueInputTwo as DropdownSelectOption[]}
            dropdownValueParam={(rowValue.valueInputTwo as DropdownSelectOption[])[0].defaultValue}
            onSelect={onChangeInputTwo}
          />
        ) : (
          notHasEmployees()
        )}
      </Grid>
    </>
  )
}

export default function RhContainer({
  rowOne,
  rowTwo,
  rowThree,
  rowFour,
  rowFive,
  rowSix,
  rowSeven,
  onChange,
  scale = 1,
}: RhContainerProps) {
  const values: any = {}

  const handleChangeInput = (key: string, value: string, error: string | null) => {
    values[key] = value
    onChange(values, error)
  }

  const handleChangeSelect = (option: DropdownSelectOption) => {
    values[option.key ?? option.label] = option.value
    onChange(values, null)
  }

  return (
    <Box1 sx={{
      height: 'auto', 
      width: '95%',
      boxSizing: 'border-box', 
      padding: scaleValue(scale, '23px 40px')
    }}>
      <Grid container spacing={scaleValue(scale, 2)}>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={4}>
          <Typography
            variant='h6'
            align='right'
            sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}
          >
            Operacionais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant='h6'
            align='right'
            sx={{ fontWeight: 'bold', color: 'gray', fontSize: scaleValue(scale, 18) }}
          >
            Vendedores
          </Typography>
        </Grid>

        <RowContainer
          title='Admitidos'
          rowValue={rowOne}
          onChangeInputOne={(event, error) =>
            handleChangeInput((rowOne.valueInputOne as RowInputProp).key, event.target.value, error)
          }
          onChangeInputTwo={(event, error) =>
            handleChangeInput((rowOne.valueInputTwo as RowInputProp).key, event.target.value, error)
          }
          tooltipHelpTextKey='admitidos'
          scale={scale}
        />
        <RowContainer
          title='Demitidos'
          rowValue={rowTwo}
          onChangeInputOne={(event, error) =>
            handleChangeInput((rowTwo.valueInputOne as RowInputProp).key, event.target.value, error)
          }
          onChangeInputTwo={(event, error) =>
            handleChangeInput((rowTwo.valueInputTwo as RowInputProp).key, event.target.value, error)
          }
          tooltipHelpTextKey='demitidos'
          scale={scale}
        />
        <RowContainer
          title='Salários($)'
          rowValue={rowThree}
          onChangeInputOne={(event, error) =>
            handleChangeInput((rowThree.valueInputOne as RowInputProp).key, event.target.value, error)
          }
          onChangeInputTwo={(event, error) =>
            handleChangeInput((rowThree.valueInputTwo as RowInputProp).key, event.target.value, error)
          }
          tooltipHelpTextKey='salario'
          scale={scale}
        />
        <RowContainerSelect
          title='Comissão'
          hasOperators={false}
          rowValue={rowFour}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='comissao'
          scale={scale}
        />
        <RowContainer
          title='Hora Extra'
          rowValue={rowFive}
          onChangeInputOne={(event, error) =>
            handleChangeInput((rowFive.valueInputOne as RowInputProp).key, event.target.value, error)
          }
          onChangeInputTwo={(event, error) =>
            handleChangeInput((rowFive.valueInputTwo as RowInputProp).key, event.target.value, error)
          }
          tooltipHelpTextKey='horaExtra'
          scale={scale}
        />
        <RowContainerSelect
          title='Treinamento'
          rowValue={rowSix}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='treinamento'
          scale={scale}
        />
        <RowContainerSelect
          title='Participação'
          hasSales={false}
          rowValue={rowSeven}
          onChangeInputOne={handleChangeSelect}
          onChangeInputTwo={handleChangeSelect}
          tooltipHelpTextKey='participacao'
          scale={scale}
        />
      </Grid>
    </Box1>
  )
}
