import { Formarters } from "./formaters"

const cache: { [key: string]: number | string } = {}

export function scaleValue(scale: number, value: number | string, factorProportion: number = 1): number | string {
  const cacheKey = `${scale}_${value}`

  const adjustedScale = factorProportion === 1 ? scale : 1 - (1 - scale) * factorProportion
  // Verifica se o resultado já está em cache
  if (cache[cacheKey]) {
    return cache[cacheKey]
  }

  let result: number | string

  if (typeof value === 'number') {
    result = value * adjustedScale
  } else if (typeof value === 'string' && value.includes('px')) {
    result = `${parseFloat(value) * adjustedScale}px`
  } else {
    result = value
  }

  // Armazena o resultado no cache para reutilização futura
  cache[cacheKey] = result

  return result
}

// Transforma tudo que vem divido por ponto num novo objeto como sua respectiva key
// Exemplo:
// {'rh.vendedores.quantidades': 2, 'compras.A.prazo': '1+1', etc} -> {rh: {vendedores: {quantidades: 2}}, compras: {A:{prazo: '1+1'}}}
export function transformToNestedObject(input: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {}

  Object.keys(input).forEach((key) => {
    const keys = key.split('.') // Divide a chave pelo '.'
    let currentLevel = result

    keys.forEach((part, index) => {
      // Se for o último nível, define o valor
      if (index === keys.length - 1) {
        currentLevel[part] = input[key]
      } else {
        // Se não existir, cria um novo objeto
        if (!currentLevel[part]) {
          currentLevel[part] = {}
        }
        currentLevel = currentLevel[part]
      }
    })
  })

  return result
}

export function getDataFromLocalStorageOrFallback<T>(
  localStorageData: any,
  fallbackData: T,
  keys: string[],
): Record<string, any> {
  return keys.reduce((acc, key) => {
    const localStorageValue = key.split('.').reduce((obj: any, k) => obj?.[k], localStorageData)
    const fallbackValue = key.split('.').reduce((obj: any, k) => obj?.[k], fallbackData)

    // Se ambos localStorageValue e fallbackValue são undefined, retorna 0
    const finalValue = localStorageValue ?? fallbackValue ?? 0

    // Monta o objeto de forma aninhada
    const keyParts = key.split('.')
    let current = acc

    keyParts.forEach((part, index) => {
      if (index === keyParts.length - 1) {
        current[part] = finalValue
      } else {
        // Assegura que a parte intermediária é um objeto
        current[part] = current[part] || {}
        current = current[part]
      }
    })

    return acc
  }, {} as Record<string, any>)
}

export function transformStringsToNumbers(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    // Verifica se o valor é uma string e se pode ser convertido para número
    if (typeof value === 'string' && !isNaN(Formarters.parseNumberWithComma(value))) {
      result[key] = Formarters.parseNumberWithComma(value) // Converte para número
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // Recursivamente aplica a transformação para objetos aninhados
      result[key] = transformStringsToNumbers(value)
    } else {
      // Mantém o valor original se não for uma string ou se não puder ser convertido
      result[key] = value
    }
  })

  return result
}

export function calculateMinutesUntilDate(futureDate: string): number {
  const now = new Date() // Data e hora atuais
  const future = new Date(futureDate) // Converte a string em objeto Date

  // Calcula a diferença em milissegundos
  const differenceInMillis = future.getTime() - now.getTime()

  // Converte a diferença para minutos
  const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60))

  if(differenceInMinutes < 0) return 0
  
  return differenceInMinutes
}

export const objectHasChanges = (objOriginal: any, changes: any): boolean => {
  let changed = false;

  // Função auxiliar para verificar recursivamente se houve mudanças
  const checkChanges = (original: any, updated: any) : any => {
    // Itera sobre as chaves do objeto de mudanças
    Object.keys(updated).forEach((key) => {
      // Verifica se o valor é um objeto e não um array ou null
      if (typeof updated[key] === 'object' && !Array.isArray(updated[key]) && updated[key] !== null) {
        // Se o original for também um objeto, faz a verificação recursiva
        if (typeof original[key] === 'object' && original[key] !== null) {
          if (checkChanges(original[key], updated[key])) {
            changed = true;
          }
        } else {
          // Se o original não for um objeto, houve mudança
          changed = true;
        }
      } else {
        // Se os valores forem diferentes, houve mudança
        if (original[key] !== updated[key]) {
          changed = true;
        }
      }
    });
  };

  // Inicia a verificação de mudanças
  checkChanges(objOriginal, changes);

  return changed;
};

export function isEdgeBrowser() : boolean {
  return /Edg/.test(navigator.userAgent);
}