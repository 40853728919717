import useMediaQuery from '@mui/material/useMediaQuery'
import { scaleValue } from '../../../lib/utils'
import { Box1, MoneyInfoBox, SatisfationMeterBox, ArrowButton } from 'architecture-front-end'
import React from 'react'
import { FolhaDecisaoDTO } from '../../../dto'
import { Formarters } from '../../../lib/formaters'

type TopContainerProps = {
  boxStyle?: React.CSSProperties
  scale?: number
  onClickArrow?: () => void
  folhaDecisaoData: FolhaDecisaoDTO
}

function TopContainer({
  folhaDecisaoData,
  boxStyle,
  scale,
  onClickArrow,
}: TopContainerProps): JSX.Element {
  scale = scale ?? 1
  const ultraMinDesktopSize = useMediaQuery('(min-width: 1020px)')
  const minDesktopSize = useMediaQuery('(min-width: 1200px)')
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)')
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)')

  let padding = '48px 44px'
  let gap = '32px'
  let boxesStyle = {}

  const internalData = {
    meu_caixa: Formarters.formatMoney(folhaDecisaoData.panel_data.meu_caixa),
    limite_emprestimo: Formarters.formatMoney(folhaDecisaoData.panel_data.limite_emprestimo),
    saldo_receber: Formarters.formatMoney(folhaDecisaoData.panel_data.saldo_receber),
    saldo_pagar: Formarters.formatMoney(folhaDecisaoData.panel_data.saldo_pagar),
    satisfacao_colaboradores:
      folhaDecisaoData.panel_data.satsfacao_colaboradores.valor.toLowerCase() === 'ruim'
        ? 0
        : folhaDecisaoData.panel_data.satsfacao_colaboradores.valor.toLowerCase() === 'regular'
        ? 50
        : 100,
  }

  if (ultraMinDesktopSize) {
    padding = '8px 2px'
    gap = '2px'
    scale = 0.2
    boxesStyle = {
      padding: '4px 0px',
    }
  }
  if (minDesktopSize) {
    //
    padding = '8px 6px'
    gap = '8px'
    scale = 0.3
    boxesStyle = {
      padding: '4px 0px',
    }
  }
  if (mediumDesktopSize) {
    padding = '32px 24px'
    gap = '16px'
    scale = 1
    boxesStyle = {
      padding: '16px 12px',
    }
  }
  if (largeDesktopSize) {
    padding = '48px 44px'
    gap = '32px'
    scale = 1
    boxesStyle = {}
  }

  const scaledBoxStyle: React.CSSProperties = {
    justifyContent: 'space-between',
    padding: padding,
    gap: gap,
    width: scaleValue(scale, 1648 - 44, 0.5),
    height: scaleValue(scale, 195 - 48, 0.5),
    background: 'white',
    position: 'relative',
  }

  // const horizontalPadding = scaleValue(scale, 36, 1); // Aplicando o scaleValue para horizontalPadding
  // const verticalPadding = scaleValue(scale, 48, 0.6); // Aplicando o scaleValue para verticalPadding

  return (
    <Box1 sx={{ ...scaledBoxStyle, ...boxStyle }}>
      <MoneyInfoBox subtitle={internalData.meu_caixa} title='Meu Caixa' scale={scale} boxStyle={boxesStyle} />
      <MoneyInfoBox
        subtitle={internalData.limite_emprestimo}
        title='Limite de Empréstimo'
        scale={scale}
        boxStyle={boxesStyle}
      />
      <MoneyInfoBox subtitle={internalData.saldo_receber} title='Saldo a Receber' scale={scale} boxStyle={boxesStyle} />
      <MoneyInfoBox subtitle={internalData.saldo_pagar} title='A Pagar' scale={scale} boxStyle={boxesStyle} />
      <SatisfationMeterBox
        title='Satisfação dos Colaboradores'
        angryEmoji='🤬'
        neutralEmoji='😐'
        happyEmoji='🤩'
        percentage={internalData.satisfacao_colaboradores}
        scale={scale === 1 ? 0.75 : scale}
        boxStyle={boxesStyle}
      />
      <ArrowButton
        direction='up'
        buttonStyle={{ position: 'absolute', left: '45%', top: '90%' }}
        onClick={onClickArrow}
      />
    </Box1>
  )
}

export default React.memo(TopContainer)