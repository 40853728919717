import { Box, Typography } from '@mui/material'
import { Box1, InputWithTitle, SelectWithTitle } from 'architecture-front-end'
import React, { useState, useEffect } from 'react'
import { scaleValue } from '../../../lib/utils'
import { Formarters } from '../../../lib/formaters'

export type DropdownSelectOption = {
  label: string
  value: string
  defaultValue?: DropdownSelectOption
  key?: string
}

export type RowInputProp = {
  key: string
  value: string
  defaultValue?: DropdownSelectOption
  disabled?: boolean
  helperText?: string
  validator?: (value: string) => string | null
}

export type Row = {
  valueInputOne?: RowInputProp | DropdownSelectOption[]
  valueInputTwo?: RowInputProp | DropdownSelectOption[]
  valueInputThree?: RowInputProp | DropdownSelectOption[]
}

export type FinanceContainerProps = {
  rowOne: Row
  rowTwo: Row
  rowThree: Row
  onChange: (data: any, error: string | null) => void
  scale?: number // Adicionando a prop de escala
}

export default function FinanceContainer({ rowOne, rowTwo, rowThree, onChange, scale = 1 }: FinanceContainerProps) {
  const [values, setValues] = useState<any>({})

  const handleChangeInput = (key: string, value: string, error: string | null) => {
    // Atualiza o valor do input no estado
    const newValues = { ...values, [key]: value }

    // Reseta o select para o valor padrão se o valor do input alterar
    if (key === (rowTwo.valueInputOne as RowInputProp).key) {
      const defaultOption = (rowTwo.valueInputTwo as DropdownSelectOption[])[0]
      newValues[defaultOption.key ?? defaultOption.label] = defaultOption.value
    }

    // Reseta o select para o valor padrão se o valor do input alterar
    if (key === (rowThree.valueInputOne as RowInputProp).key) {
      const defaultOption = (rowThree.valueInputTwo as DropdownSelectOption[])[0]
      newValues[defaultOption.key ?? defaultOption.label] = defaultOption.value
    }

    setValues(newValues)
    onChange(newValues, error)
  }

  const handleChangeSelect = (option: DropdownSelectOption) => {
    setValues({ ...values, [option.key ?? option.label]: option.value })
    onChange(values, null)
  }


  function renderSelectTwo(options: DropdownSelectOption[]) {
    const v =
      values[(rowTwo.valueInputOne as RowInputProp).key] === undefined ?
      Number.parseInt((rowTwo.valueInputOne as RowInputProp).value) :
      Formarters.parseNumberWithComma(values[(rowTwo.valueInputOne as RowInputProp).key])
      
    const isZero = v === 0 || Formarters.parseNumberWithComma(isNaN(v))

    if (isZero) {
      return (
        <SelectWithTitle
          title='Prazo de Pagamento'
          tooltipText='(É uma aplicação com prazo determinado para saque e com taxa de juros pré-fixada.) tempo em que o valor da Aplicação 2 ficará investido (2 ou 3 Etapas).'
          options={[options[0]]}
          option={options[0]}
          onSelect={handleChangeSelect}
        />
      )
    } else {
      return (
        <SelectWithTitle
          title='Prazo de Pagamento'
          tooltipText='(É uma aplicação com prazo determinado para saque e com taxa de juros pré-fixada.) tempo em que o valor da Aplicação 2 ficará investido (2 ou 3 Etapas).'
          options={options.slice(1)}
          option={options[1]}
          onSelect={handleChangeSelect}
        />
      )
    }
  }

  function renderSelectThree(options: DropdownSelectOption[]) {
    const v =
      values[(rowThree.valueInputOne as RowInputProp).key] === undefined ?
      Number.parseInt((rowThree.valueInputOne as RowInputProp).value) :
      Formarters.parseNumberWithComma(values[(rowThree.valueInputOne as RowInputProp).key])
      
    const isZero = v === 0 || Formarters.parseNumberWithComma(isNaN(v))

    if (isZero) {
      return (
        <SelectWithTitle
          title='Prazo de Pagamento'
          tooltipText='tempo para a previsão de pagamento do empréstimo solicitado (2 ou 3 Etapas).'
          options={[options[0]]}
          option={options[0]}
          onSelect={handleChangeSelect}
        />
      )
    } else {
      return (
        <SelectWithTitle
          title='Prazo de Pagamento'
          tooltipText='tempo para a previsão de pagamento do empréstimo solicitado (2 ou 3 Etapas).'
          options={options.slice(1)}
          option={options[1]}
          onSelect={handleChangeSelect}
        />
      )
    }
  }

  return (
    <Box1
      display='flex'
      flexDirection='column'
      sx={{
        height: 'auto',
        width: '95%',
        boxSizing: 'border-box',
        padding: scaleValue(scale, '23px 40px'),
      }}
    >
      <Box display='flex' flexDirection='column' gap={scaleValue(scale, '32px')} marginTop={scaleValue(scale, '12px')}>
        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Typography
            sx={{
              width: '100%',
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Aplicação 1
          </Typography>
          <Box display='flex' gap={scaleValue(scale, '21px')} sx={{ justifyContent: 'space-between' }}>
            <InputWithTitle
              title='Depósito($)'
              tooltipText='(Pode ser considerada como similar ao investimento numa poupança.) valor a ser depositado na Aplicação 1.' 
              initialAdornment='$'
              validator={(rowOne.valueInputOne as RowInputProp).validator}
              helperText={(rowOne.valueInputOne as RowInputProp).helperText}
              onChange={(event, error) => handleChangeInput((rowOne.valueInputOne as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowOne.valueInputOne as RowInputProp).value}
            />
            <InputWithTitle
              title='Saque($)'
              tooltipText='(Pode ser considerada como similar ao investimento numa poupança.) valor do saque da Aplicação 1.'
              initialAdornment='$'
              validator={(rowOne.valueInputTwo as RowInputProp).validator}
              helperText={(rowOne.valueInputTwo as RowInputProp).helperText}
              onChange={(event, error) => handleChangeInput((rowOne.valueInputTwo as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowOne.valueInputTwo as RowInputProp).value}
            />
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Typography
            sx={{
              width: '100%',
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Aplicação 2
          </Typography>
          <Box display='flex' gap={scaleValue(scale, '21px')}>
            <InputWithTitle
              title='Depósito($)'
              tooltipText='(É uma aplicação com prazo determinado para saque e com taxa de juros pré-fixada.) valor a ser depositado na Aplicação 2.' 
              initialAdornment='$'
              validator={(rowTwo.valueInputOne as RowInputProp).validator}
              helperText={(rowTwo.valueInputOne as RowInputProp).helperText}
              onChange={(event, error) => handleChangeInput((rowTwo.valueInputOne as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowTwo.valueInputOne as RowInputProp).value}
            />
            {renderSelectTwo(rowTwo.valueInputTwo as DropdownSelectOption[])}
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Typography
            sx={{
              width: '100%',
              color: 'black',
              fontSize: scaleValue(scale, 22),
              fontFamily: 'Poppins',
              fontWeight: '700',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            Empréstimo Normal
          </Typography>
          <Box display='flex' gap={scaleValue(scale, '21px')}>
            <InputWithTitle
              title='Depósito($)'
              tooltipText='valor a ser tomado de empréstimo (verifique as taxas).'
              initialAdornment='$'
              validator={(rowThree.valueInputOne as RowInputProp).validator}
              helperText={(rowThree.valueInputOne as RowInputProp).helperText}
              onChange={(event, error) => handleChangeInput((rowThree.valueInputOne as RowInputProp).key, event.target.value, error)}
              defaultValue={(rowThree.valueInputOne as RowInputProp).value}
            />
            {renderSelectThree(rowThree.valueInputTwo as DropdownSelectOption[])}
          </Box>
        </Box>
      </Box>
    </Box1>
  )
}
