import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { ArrowButton, ErrorModal, SuccessModal } from 'architecture-front-end';
import {
  BottomContainer,
  TopContainer,
  LeftContainer,
  RightContainer,
  MidContainer,
} from './components/Templates';
import { objectHasChanges, scaleValue, transformToNestedObject } from './lib/utils';
import {
  useGameDetails,
  useGameResults,
  useSendGameResults,
} from './api/game';
import { FolhaDecisaoDTO, JornalDTO, Ranking } from './dto';
import { fetchJornalResults, useJornalResults } from './api/jornal';
import { replaceObjWithChanges } from './lib/replaceObjWIthChanges';
import { deserializeAndSerialize } from './lib/serialize';
import { useEtapaResults } from './api/etapa';
import { EtapaDTO } from './dto/etapaDTO';
import { GameDetailsDTO } from './dto/gameDetailsDTO';
import { QueryClient } from 'react-query';

interface Error {
  msg: string;
  param: string;
  location: string;
}

function MainPage({ queryClient } : {queryClient: QueryClient}) {
  const [showTopContainer, setShowTopContainer] = useState(true);
  const [showRightContainer, setShowRightContainer] = useState(true);
  const ultraMinDesktopSize = useMediaQuery('(min-width: 1020px)');
  const minDesktopSize = useMediaQuery('(min-width: 1200px)');
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)');
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)');
  
  const [midContainerSize, setMidContainerSize] = useState({ width: 0, height: 0 });
  const midContainerRef = useRef<HTMLDivElement>(null); // Ref para capturar o contêiner do grid
  const midContainerDataRef = useRef<any>({
    current: {}
  });

  const [showModalError, setShowModalError] = useState(false);
  const [sendButtonActive, setSendButtonActive] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [dataJornal, setDataJornal] = useState<JornalDTO[] | null>(null);
  const [isNextStepButtonPressed, setIsNextStepButtonPressed] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);

  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const { data: dataGameDetails, refetch: refetchGameDetails } = useGameDetails(jogoId);
  const { data: dataGame, isLoading, error, refetch: refetchGameResults } = useGameResults(jogoId);
  const { data: dataEtapa, isLoading: isLoadingEtapa, error: errorEtapa, refetch: refetchEtapa } = useEtapaResults(jogoId);

  const {
    data: dataSent,
    isLoading: isLoadingSent,
    mutateAsync,
    isSuccess,
    isError,
    error: errorApi,
  } = useSendGameResults(jogoId);

  useEffect(() => {
    if (errorApi) {
      setShowModalError(isError);
    }
    if (isSuccess) {
      setShowModalSuccess(true);
    }
  }, [isError, errorApi, isSuccess]);

  // Verifica se os dados de etapa estão prontos e chama o useJornalResults apenas após isso
  useEffect(() => {
    let isNewEtapa = false;
    if (dataEtapa && dataEtapa.numero_etapa > parseInt(localStorage.getItem('numero_etapa') ?? '')) {
      const queryParams = new URLSearchParams(window.location.search)
      const token = queryParams.get('token')
      const jogo_id = queryParams.get('jogo_id')
      const id_empresa = queryParams.get('id_empresa')
      const id_etapa = dataEtapa.id.toString()

      localStorage.setItem('id_etapa', id_etapa)
      localStorage.setItem('numero_etapa', dataEtapa.numero_etapa.toString())
      // refetchGameDetails()
      // refetchGameResults()
 
      // // Invalida queries relacionadas ao jogo para garantir que refaça o fetch
      // queryClient.invalidateQueries(['gameResults', jogoId])
      // queryClient.invalidateQueries(['gameDetails', jogoId])
      
      isNewEtapa = true;
      setIsNextStepButtonPressed(false);
      window.location.href = '/mainpage?token=' + token + '&jogo_id=' + jogo_id + '&id_etapa=' + id_etapa + '&id_empresa=' + id_empresa
    } else {
      if(isNextStepButtonPressed && dataEtapa && dataEtapa.numero_etapa) {
        alert('Próxima etapa indisponivel, ou ainda não liberada.')
        setIsNextStepButtonPressed(false);
      }
    }

    if (dataEtapa && !isLoadingEtapa) {
      if (isNewEtapa || dataJornal === null) {
        fetchJornalResults(jogoId, dataEtapa.numero_etapa).then((res) => {
          localStorage.setItem('numero_etapa', dataEtapa.numero_etapa.toString())
          setDataJornal(res); // Define os dados do Jornal no estado
        }).catch((err) => {
          console.error(err);
        });
      }
    }
  }, [dataEtapa, isLoadingEtapa, jogoId, dataJornal, refetchGameDetails, refetchGameResults, isNextStepButtonPressed]);

  
  useEffect(() => {
    // Atualiza as dimensões do MidContainer quando o componente é montado ou quando o layout muda
    const updateMidContainerSize = () => {
      if (midContainerRef.current) {
        const { offsetWidth, offsetHeight } = midContainerRef.current;
        setMidContainerSize({
          width: offsetWidth,
          height: offsetHeight,
        });
      }
    };

    updateMidContainerSize(); // Atualiza ao montar

    window.addEventListener('resize', updateMidContainerSize); // Adiciona listener para redimensionamento da tela
    return () => {
      window.removeEventListener('resize', updateMidContainerSize); // Remove o listener quando o componente é desmontado
    };
  }, [showTopContainer, showRightContainer, dataGame]); // Recalcula quando containers visíveis mudam


  // Renderiza um "Loading" enquanto o login está sendo feito
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Preenche toda a altura da viewport
        }}
      >
        <CircularProgress color='success' size={80} />
      </Box>
    )
  }

  if (error) return <div>Error ao carregar a simulação</div>;

  // Função para definir as áreas do grid dinamicamente com base na visibilidade dos containers
  const getGridTemplateAreas = () => {
    if (!showTopContainer && !showRightContainer) {
      return `
        "left mid mid mid"
        "left mid mid mid"
        "left bottom bottom bottom"
      `;
    }
    if (!showTopContainer && showRightContainer) {
      return `
        "left mid mid right"
        "left mid mid right"
        "left bottom bottom bottom"
      `;
    }
    if (showTopContainer && !showRightContainer) {
      return `
        "left top top top"
        "left mid mid mid"
        "left bottom bottom bottom"
      `;
    }
    return `
      "left top top top"
      "left mid mid right"
      "left bottom bottom bottom"
    `;
  };

  let scale = 1;
  if (ultraMinDesktopSize) {
    scale = 0.7;
  }
  if (minDesktopSize) {
    scale = 0.75;
  }
  if (mediumDesktopSize) {
    scale = 0.85;
  }
  if (largeDesktopSize) {
    scale = 1;
  }

  let debounceTimeout: NodeJS.Timeout | null = null;

  const handleMidContainerChange = (newData: any) => {
    midContainerDataRef.current = transformToNestedObject({
      ...midContainerDataRef.current,
      ...newData,
    }); // Atualiza a ref sem re-renderizar

    if (midContainerDataRef.current && objectHasChanges(dataGame, midContainerDataRef.current)) {
      setSendButtonActive(true);
    } else {
      setSendButtonActive(false);
    }

    // Limpa o timeout anterior
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Define um novo timeout para atualizar o sessionStorage após 500ms (por exemplo)
    debounceTimeout = setTimeout(() => {
      sessionStorage.setItem(
        'midContainerData',
        JSON.stringify(midContainerDataRef.current)
      );
      
      if(newData) {
        let forceUpdate = false
        const keys = [
          'compras.A.prazo_pagamento',
          'compras.B.prazo_pagamento',
          'compras.C.prazo_pagamento',
          'compras.D.prazo_pagamento',
          'compras.E.prazo_pagamento',
          'vendas.A.prazo_pagamento',
          'vendas.B.prazo_pagamento',
          'vendas.C.prazo_pagamento',
          'vendas.D.prazo_pagamento',
          'vendas.E.prazo_pagamento',
        ]
        if(keys.some(key => key in newData)) {
          forceUpdate = true
        }
  
        if(forceUpdate) {
          setForceUpdate(prev => prev + 1); // Força a re-renderização
        }
      }
    }, 1000); // 1s de debounce, ajuste conforme necessário
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: getGridTemplateAreas(), // Áreas do grid adaptadas à visibilidade
        gridTemplateColumns: `${scaleValue(
          scale,
          '302px',
          0.3
        )} 1fr 1fr ${showRightContainer ? scaleValue(scale, '262px', 0.3) : '0px'}`,
        gridTemplateRows: `${showTopContainer ? scaleValue(scale, '195px', 1) : '0px'} 1fr 71px`,
        gap: '0px',
        height: '100vh',
        position: 'relative',
        background: 'rgba(245, 245, 245, 1)',
      }}
    >
      {/* Success modal para mostrar dados de sucesso vindo no submit do MidContainer para api */}
      {isSuccess && (
        <SuccessModal
          modalIsOpen={showModalSuccess}
          onClose={() => setShowModalSuccess(false)}
          successMessage='Dados enviados com sucesso!'
        />
      )}

      {/* Error modal para mostrar dados de erros vindo no submit do MidContainer para api */}
      {isError && errorApi && (
        <ErrorModal
          modalIsOpen={showModalError}
          onClose={() => setShowModalError(false)}
          errorMessage={JSON.parse(errorApi.message).message}
          errors={JSON.parse(errorApi.message).errors as Error[]}
        />
      )}

      {/* Left Container */}
      <Box sx={{ gridArea: 'left', width: scaleValue(
          scale,
          '302px',
          0.3
        ), height: '100%', position: 'fixed', zIndex: 5, overflowY: 'overlay', overflowX: 'hidden'}}>
        <LeftContainer
          gameDetails={dataGameDetails as GameDetailsDTO}
          ranking={dataGame?.ranking as Ranking[]}
          boxStyle={{
            boxSizing: 'border-box',
            width: '101%',
            height: '100%',
            borderRadius: 0,
            position: 'relative',
            zIndex: 4,
          }}
        />
      </Box>

      {/* Top Container */}
      {showTopContainer && (
        <Box
          sx={{
            gridArea: 'top',
            position: 'absolute', // Sobreposto ao MidContainer
            top: 0,
            left: 0,
            right: 0,
            height: scaleValue(scale, '195px', 1),
            zIndex: 2,
          }}
        >
          <TopContainer
            folhaDecisaoData={dataGame as FolhaDecisaoDTO}
            boxStyle={{
              boxSizing: 'border-box',
              width: '100%',
              height: '100%',
              borderRadius: 0,
              alignItems: 'center',
            }}
            scale={0.5}
            onClickArrow={() => setShowTopContainer(false)}
          />
        </Box>
      )}

      {/* Right Container */}
      {showRightContainer && dataJornal && (
        <Box
          sx={{
            gridArea: 'right',
            position: 'absolute', // Sobreposto ao MidContainer
            top: `${false ? '195px' : '0px'}`, // Ajusta a posição com base no TopContainer
            right: 0,
            bottom: 0,
            width: scaleValue(scale, '262px', 1),
            zIndex: 1,
          }}
        >
          <RightContainer
            jornais={dataJornal as JornalDTO[]}
            boxStyle={{
              boxSizing: 'border-box',
              width: '100%',
              height: '100%',
              borderRadius: 0,
            }}
            onClickArrow={() => setShowRightContainer(false)}
          />
        </Box>
      )}

      {/* Mid Container (não se ajusta, permanece fixo) */}
      <Box
        ref={midContainerRef} // Aplica a referência ao contêiner
        sx={{
          gridArea: 'mid',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 0, // Fica atrás dos containers sobrepostos
        }}
      >
        <MidContainer
          midContainerData={midContainerDataRef.current}
          onChange={handleMidContainerChange}
          folhaDecisaoData={dataGame as FolhaDecisaoDTO}
          dataEtapa={dataEtapa as EtapaDTO}
          scale={scale}
          boxStyle={{
            position: 'fixed',
            width: midContainerSize.width, // Ajusta a largura
            height: midContainerSize.height, // Ajusta a altura
          }}
        />
      </Box>

      {/* Bottom Container */}
      <Box sx={{ gridArea: 'bottom' }}>
        <BottomContainer
          etapa={dataEtapa as EtapaDTO}
          sendButtonActive={sendButtonActive}
          // sendButtonActive={!!midContainerDataRef.current}
          sendFunction={async () => {
            if (midContainerDataRef.current) {
              const newObj = replaceObjWithChanges(
                dataGame,
                midContainerDataRef.current
              );
              try {
                const objToSend = {
                  compras: newObj?.compras,
                  financas: newObj?.financas,
                  vendas: newObj?.vendas,
                  rh: newObj?.rh,
                  marketing: newObj?.marketing,
                  contas_pagar: newObj?.contas_pagar,
                }
                const searchCustPriceByPaymentTerm = (key: string) => {
                  if (objToSend.compras[key]?.prazo_pagamento === 1 || objToSend.compras[key]?.prazo_pagamento === 0) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa
                  } else if (objToSend.compras[key]?.prazo_pagamento === 2) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas
                  } else if (objToSend.compras[key]?.prazo_pagamento === 3) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas
                  } else if (objToSend.compras[key]?.prazo_pagamento === 20) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas_sem_entrada
                  } else if (objToSend.compras[key]?.prazo_pagamento === 30) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas_sem_entrada
                  } else {
                    return 0
                  }
                }
                for(let key in objToSend.compras) {
                  objToSend.compras[key].preco_custo = searchCustPriceByPaymentTerm(key)
                }
                await mutateAsync(objToSend)
              } catch (error) {
                // ...
              }
            }
          }}
          nextStepFunction={async () => {
            try {
              await refetchEtapa();
              setIsNextStepButtonPressed(true);
            } catch (error) {
              // ...
            }
          }}
          boxStyle={{
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
            borderRadius: 0,
            position: 'relative',
            zIndex: 2,
          }}
        />
      </Box>

      {/* Botões para mostrar novamente os containers ocultos */}
      {!showTopContainer && (
        <ArrowButton
          direction='down'
          buttonStyle={{ position: 'absolute', top: '0%', left: '53%' }}
          onClick={() => setShowTopContainer(true)}
        />
      )}
      {!showRightContainer && (
        <ArrowButton
          direction='left'
          buttonStyle={{ position: 'absolute', top: '51%', right: '0%' }}
          onClick={() => setShowRightContainer(true)}
        />
      )}
    </Box>
  );
}

export default MainPage;
