import { useQuery } from 'react-query'
import { EtapaDTO } from '../dto/etapaDTO'

const fetchEtapaResults = async (jogo_id: string): Promise<EtapaDTO> => {
  const token = localStorage.getItem('token') // Pegando o token do localStorage

  const response = await fetch(
    `
  ${process.env.REACT_APP_BACKEND_URL}/jogos/${jogo_id}/etapa/liberada`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )

  if (!response.ok) {
    throw new Error('Failed to fetch jornal results')
  }
  return response.json()
}

export function useEtapaResults(jogo_id: string) {
  return useQuery({
    queryKey: ['etapaResults', jogo_id],
    queryFn: () => fetchEtapaResults(jogo_id),
    staleTime: Infinity,
  })
}
