import { CircularProgress, Box } from '@mui/material';
import { useRelatorioResults } from '../../../api/relatorio'
import { isEdgeBrowser } from '../../../lib/utils';

export default function CompanyReport(): JSX.Element {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const showHtml = isEdgeBrowser()
  const { data, isLoading, error } = useRelatorioResults(jogoId, 'empresa', showHtml)

  if (isLoading) return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh', // Preenche toda a altura da viewport
      }}
    >
      <CircularProgress color='success' size={60} />
    </Box>
  )
  if (error) return <div>Erro ao carregar os dados da empresa</div>

  function renderHtmlOrPdf() {
    if (showHtml) {
      return <iframe srcDoc={data} style={{ width: '85vw', height: '100vh', border: 'none' }} title='Company Report' />
    } else {
      return <iframe src={data} style={{ width: '100%', height: '100vh', border: 'none' }} title='Company Report' />
    }
  }

  return (
    <>
      {data ? (
       renderHtmlOrPdf()
      ) : (
        <div>Sem Conteudo.</div>
      )}
    </>
  )
}
